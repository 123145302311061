
import {
  Component,
  Vue
} from 'vue-property-decorator'
// import JobHeader from '@/views/job/components/Operation/Header/index.vue'
import JobService from '../../services/job-service'
// import BargeService from '../../services/barge-service'
// import UomJobs from '@/data/uom-jobs.json'
import {
  JobModule
} from '../../store/JobModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
// import BowserService from '../../services/bowser-service'
import ApprovalService from '../../services/approval-service'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'JobTable',
    components: {}
  })
export default class SLPATable extends Vue {
    // SLPA job table variables
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected searchTable = null
    protected jobs: any = []
    protected approval: any = {}
    protected serviceType: any = null
    public items: any = []
    protected fields = [{
      key: 'reference_number',
      label: 'Job No.',
      sortable: true,
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vessel_name',
      label: 'Vessel Name',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'service_type',
      label: 'Service Type',
      sortable: true,
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'is_agent_letter',
      label: 'Agent Letter',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'is_import_export_license',
      label: 'Import Export License',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'is_sri_lanka_customs',
      label: 'Sri Lanka Customs Approval',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'is_harbor_master',
      label: 'Harbour Master Approval',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'is_slpa_security',
      label: 'SLPA Security Approval',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'is_sri_lanka_navy',
      label: 'Sri Lanka Navy Approval',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'is_slpa_trader_form',
      label: 'SLPA Trader Form',
      thStyle: {
        width: '8%'
      }
    },
    /* {
      key: 'lines',
      label: 'Services',
      thStyle: {
        width: '15%'
      },
      sortable: true
    },
    {
      key: 'status',
      label: 'Status',
      class: 'text-center',
      sortable: true
    }, */
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '5%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.boot()
    }

    get rows () {
      return this.jobs.length
    }

    /* get serviceTypes () {
      return this.jobs.forEach(job => {
        return job.lines.service_type
      })
    } */

    get jobHeader () {
      return JobModule.job
    }

    protected boot () {
      this.populateSLPAJobs()
      BreadcrumbModule.setBreadcrumb(this.$route)
    }

    protected async populateSLPAJobs () {
      // BreadcrumbModule.setBreadcrumb(this.$route)
      const response = await JobService.getSLPAJobs(AuthModule.party.id)
      this.jobs = response.data.map(job => ({
        ...job,
        reference_number: job.reference_number,
        vessel_name: job.vessel_name,
        job_number: job.job_number,
        job_id: job.id,
        is_agent_letter: false,
        is_import_export_license: false,
        is_sri_lanka_customs: false,
        is_harbor_master: false,
        is_slpa_security: false,
        is_sri_lanka_navy: false,
        is_slpa_trader_form: false,
        // modified_user: AuthModule.user.fullname,
        // job_line_id: job.lines.map(line => (line.id)),
        service_types: job.lines.map(line => (line.service_type))
      }))
      /* this.jobs = reponse.data
      return true */
    }

    public saveApprovals (job) {
      this.approval.job_id = job.job_id
      this.approval.job_number = job.job_number
      this.approval.service_type = this.serviceType
      this.approval.is_agent_letter = job.is_agent_letter
      this.approval.is_import_export_license = job.is_import_export_license
      this.approval.is_sri_lanka_customs = job.is_sri_lanka_customs
      this.approval.is_harbor_master = job.is_harbor_master
      this.approval.is_slpa_security = job.is_slpa_security
      this.approval.is_sri_lanka_navy = job.is_sri_lanka_navy
      this.approval.is_slpa_trader_form = job.is_slpa_trader_form
      this.approval.modified_user = AuthModule.user.fullname
      this.approval.created_user = AuthModule.user.fullname
      // console.log(this.approval.job_line_id)
      ApprovalService.save(this.approval).then((response) => {
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    public hideModal () {
      (this.$refs.Approvals as any).hide();
      (this.$refs.Bowsers as any).hide();
      (this.$refs.Mepa as any).hide();
      (this.$refs.DispatchNote as any).hide()
    }

    protected async setApprovals (item: any) {
      JobModule.setJob(item.id)
    }

    public reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

